<template>
    <div id="secure">
        <h1>Neuen PAW erstellen</h1>
        <div v-if="step === 1" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-50 md-small-size-90">
                <md-card-header>
                    <div class="md-title">Für wen sind die orthopädischen Einlagen bestimmt?</div>
                </md-card-header>
                <md-card-content>
                    <form>
                        <md-field>
                            <label for="description">Bezeichnung (automatisch)</label>
                            <md-input id="description" v-model="paw_description" autocomplete="off" disabled name="description"
                                      type="text"/>
                        </md-field>
                        <md-field>
                            <label for="firstName">Vorname</label>
                            <md-input id="firstName" v-model="paw.forename" :disabled="sending" autocomplete="off"
                                      name="firstName"
                                      type="text"/>
                        </md-field>
                        <md-field>
                            <label for="lastName">Nachname</label>
                            <md-input id="lastName" v-model="paw.name" :disabled="sending" autocomplete="off" name="lastName"
                                      type="text"/>
                        </md-field>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>Geburtsdatum</label>
                                    <md-input v-model="paw.birthdate" type="date"></md-input>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>Geschlecht</label>
                                    <md-select v-model="paw.gender" name="gender" placeholder="Geschlecht">
                                        <md-option value=-1>bitte auswählen</md-option>
                                        <md-option value=0>männlich</md-option>
                                        <md-option value=1>weiblich</md-option>
                                        <md-option value=2>divers</md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                    </form>
                    Diese Daten sind alle verpflichtend.
                    Falls die Einlagen auf Rezept gefertigt werden müssen diese Daten mit denen auf dem Rezept übereinstimmen.
                </md-card-content>
                <md-card-actions>
                    <md-button :disabled="!requiredDataValid" class="md-raised md-primary" v-on:click="stepForward()">
                        Weiter
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>

        <div v-if="step === 2" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-50 md-small-size-90">
                <md-card-header>
                    <div class="md-title">Körpermaße</div>
                </md-card-header>
                <md-card-content>
                    <form>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label for="weight">Gewicht (in kg)</label>
                                    <md-input id="weight" v-model="paw.weight" :disabled="sending" autocomplete="off"
                                              name="weight" type="number"/>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label for="shoeSize">Schuhgröße</label>
                                    <md-input id="shoeSize" v-model="paw.shoeSize" :disabled="sending" autocomplete="off"
                                              name="shoeSize" type="text"/>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label for="height">Körpergröße in cm</label>
                                    <md-input id="height" v-model="paw.height" :disabled="sending" autocomplete="off"
                                              name="height" type="number"/>
                                </md-field>
                            </div>
                        </div>
                    </form>
                    Diese Daten werden benötigt um die Einlagen perfekt auf Dich abzustimmen.
                </md-card-content>
                <md-card-actions>
                    <md-button class="md-raised md-primary" v-on:click="stepBack()">
                        Zurück
                    </md-button>
                    <md-button class="md-raised md-primary" v-on:click="stepForward()">
                        Weiter
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>

        <div v-if="step === 3" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-50 md-small-size-90">
                <md-card-header>
                    <div class="md-title">Abweichende Lieferadresse</div>
                </md-card-header>
                <md-card-content>
                    Folgende Adresse haben wir in Deinem Nutzerprofil gespeichert:
                    <br>
                    {{ user_address.street }}
                    <br>
                    {{ user_address.postCode }}
                    {{ user_address.city }}
                    <br>
                    {{ user_address.country }}
                    <br>
                    <form>
                        <md-switch v-model="altAddress">Abweichende Adresse nutzen</md-switch>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label for="street">Straße Hausnummer</label>
                                    <md-input id="street" v-model="paw.alternativeDeliveryAddress.street" :disabled="!altAddress"
                                              autocomplete="shipping street-address"
                                              name="street"/>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label for="zipCode">PLZ</label>
                                    <md-input id="zipCode" v-model="paw.alternativeDeliveryAddress.postCode"
                                              :disabled="!altAddress" autocomplete="shipping postal-code"
                                              name="zipCode"/>
                                </md-field>
                            </div>

                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label for="city">Stadt</label>
                                    <md-input id="city" v-model="paw.alternativeDeliveryAddress.city" :disabled="!altAddress"
                                              autocomplete="address-level2"
                                              name="city"/>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div v-if="false" class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label for="country">Stadt</label>
                                    <md-input id="country" v-model="paw.alternativeDeliveryAddress.country" autocomplete="off"
                                              disabled
                                              name="country"/>
                                </md-field>
                            </div>
                        </div>
                    </form>
                </md-card-content>
                <md-card-actions>
                    <md-button :disabled="sending" class="md-raised md-primary" v-on:click="stepBack()">
                        Zurück
                    </md-button>
                    <md-button :disabled="sending" class="md-raised md-primary" v-on:click="stepForward()">
                        Weiter
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>

        <div v-if="step === 4" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-50 md-small-size-90">
                <md-card-header>
                    <div class="md-title">Anmerkungen</div>
                </md-card-header>
                <md-card-content>
                    <form>
                        <md-field>
                            <label for="comment">Anmerkungen</label>
                            <md-textarea id="comment" v-model="paw.comment" :disabled="sending" autocomplete="on"
                                         md-autogrow name="comment"/>
                        </md-field>
                    </form>
                </md-card-content>
                <md-card-actions>
                    <md-button :disabled="sending" class="md-raised md-primary" v-on:click="stepBack()">
                        Zurück
                    </md-button>
                    <md-button :disabled="sending" class="md-raised md-primary" v-on:click="createPaw()">
                        Speichern
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>

        <div v-if="step === 5" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-50 md-small-size-90">
                <md-card-header>
                    <div class="md-title">Weitermachen?</div>
                </md-card-header>
                <md-card-content>
                    Ihre Angaben wurden gespeichert.
                    Möchten Sie direkt die Bilder machen?
                </md-card-content>
                <md-card-actions>
                    <md-button class="md-raised md-primary" :to="'/user/paw/detail/'+paw.id">
                        Nein
                    </md-button>
                    <md-button class="md-raised md-primary" v-on:click="gotoAddImages()">
                        Ja
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>
        <md-button v-show="step < 5" class="md-raised md-accent" to="/user/dashboard/">Erstellen Abbrechen</md-button>
        <md-button v-show="step === 5" class="md-raised md-accent" to="/user/dashboard/">zur Übersicht</md-button>
    </div>
</template>

<script>
import {dateFormat} from "../helpers/dates"

export default {
    name: 'User_PAW_new',
    components: {},
    props: {},
    data: () => ({
        step: 1,

        altAddress: false,

        paw: {
            // Step 1 - required - not nullable
            forename: "",
            name: "",
            gender: -1,
            birthdate: new Date(),

            // Step 2 - optional - not nullable
            height: "",
            weight: "",
            shoeSize: "",

            // Step 3 - optional - nullable
            alternativeDeliveryAddress: {
                street: "",
                postCode: "",
                city: "",
                country: "Deutschland"
            },

            // Step 4 - optional - not nullable
            comment: "",
        },

        tmp_image: undefined,
    }),
    computed: {
        commentHTML: function () {
            return this.paw.comment.replace(/\r/g, "").replace(/\n/g, "<br>")
        },
        sending: function () {
            return this.$store.state.rxtx > 0
        },
        paw_description: function () {
            const forename = this.paw.forename.length > 0 ? this.paw.forename : "Vorname";
            const name = this.paw.name.length > 0 ? this.paw.name : "Nachname";
            return name + "_" + forename + "_" + dateFormat(new Date, "myDate")
        },
        requiredDataValid: function () {
            const p = this.paw;
            return p.forename.length > 0 && p.name.length > 0 && p.gender >= 0;
        },
        user_address: function () {
            return this.$store.state.user.address
        }
    },
    methods: {
        stepForward() {
            this.step += 1
        },
        stepBack() {
            this.step -= 1
        },
        createPaw() {
            const t = this
            const p = this.paw
            const altAdd = p.alternativeDeliveryAddress
            if (!this.altAddress) {
                p.alternativeDeliveryAddress = null
            }
            p.description = this.paw_description
            console.log(this.paw)
            this.$store.dispatch("createPAW", p).then(function (result) {
                t.paw.id = result
                console.log("new PAW: " + result)
                t.$store.dispatch("showSnackbar", {text: "PAW erfolgreich gespeichert"})
                t.stepForward()
            }).catch(() => {
                p.alternativeDeliveryAddress = altAdd
            })
        },
        gotoAddImages() {
            this.$router.push("/user/paw/addimages/" + this.paw.id);
        }
    }
}
</script>

<style scoped>
#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}

table {
    display: inline-block;
    border-collapse: collapse;
}

table > tr > th {
    font-size: large;
    padding: 10px;
    white-space: nowrap;
}

table > tr > td {
    padding: 10px;
}

table > tr:nth-child(odd) {
    background: #EEE;
}

table > tr:nth-child(even) {
    background: #FFF;
}
</style>


<!-- TODO Werte validieren -->
